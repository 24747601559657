import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid, List,
  ListProps, TextField
} from 'react-admin'
import { ListActionsToolbar } from '../../components'
import { LocationCreateButton } from '../../components/buttons/LocationCreateButton'
import { OrganizationWarningCard } from '../../components/cards/OrganizationWarningCard'

const MunicipalitiesList: FC<ListProps> = (props) => (
  <>
    <OrganizationWarningCard />
    <StyledList
      {...props}
      exporter={false}
      actions={
        <ListActionsToolbar i18n="resources.municipalities.list">
          <LocationCreateButton
            access={['configuration:all']}
            resource="municipality"
            label="ra.action.save"
          />
        </ListActionsToolbar>
      }
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField
          source="name"
          label="data.name"
        />
        <TextField
          source="municipalityCode"
          label="data.municipalityCode"
        />
      </Datagrid>
    </StyledList>
  </>
)

const PREFIX = 'xxllncMunicipalitiesList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default MunicipalitiesList
