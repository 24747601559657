import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { FC, SyntheticEvent, useState } from 'react'
import { required, SelectInput, TextInput, useTranslate } from 'react-admin'
import { ObjectionType } from '../../../types/apiRequests'

interface ControlledAccordionsProps {
    disabled?: boolean
}

const ControlledAccordions: FC<ControlledAccordionsProps> = ({ disabled }) => {
  const translate = useTranslate()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography component="span" sx={{ width: '33%', flexShrink: 0 }}>
            { translate('data.objectionConfig') }
          </Typography>
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            { translate('data.objectionConfigDescription') }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextInput
            label="data.object_woz_number"
            source="object_woz_number"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.referentieNummer"
            source="object_reference_number"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.straat"
            source="object_street"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.huisnummer"
            source="object_house_number"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.postalCode"
            source="object_postal_code"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.city"
            source="object_city"
            InputProps={{
              disabled
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography component="span" sx={{ width: '33%', flexShrink: 0 }}>
            { translate('data.subject') }
          </Typography>
          <Typography component="span" sx={{ color: 'text.secondary' }}>
            { translate('data.subjectDescription') }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectInput
            source="type"
            label="data.type"
            choices={Object.values(ObjectionType).map(value => ({
              id: value,
              name: `resources.objections.objectionType.${value}`
            }))}
            validate={required()}
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.externalReference"
            source="external_reference"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.name"
            source="subject_name"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.straat"
            source="subject_street"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.huisnummer"
            source="subject_house_number"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.postalCode"
            source="object_postal_code"
            InputProps={{
              disabled
            }}
          />
          <TextInput
            label="data.city"
            source="subject_city"
            InputProps={{
              disabled
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default ControlledAccordions