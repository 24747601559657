import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { FC, useState } from 'react'
import {
  Button,
  CreateBase,
  FileField,
  FileInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useGetList,
  useNotify, useRefresh,
  useTranslate
} from 'react-admin'
import { MunicipalityOut } from '../../types/apiRequests'

const SaveToolbar = props => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
)

const UploadMultipleDocumentsButton: FC = () => {
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const { data: municipalities } = useGetList<MunicipalityOut>('municipality')

  const isSingleMunicipality = municipalities && municipalities.length === 1
  const hasAtLeastOneMunicipality = municipalities && municipalities.length > 0

  const redirect = (resource, _id, _data) => {
    setOpen(false)
    notify('resources.objections.upload.success', { type: 'info' })
    refresh()
    return `${resource}/`
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        label={translate('resources.objections.upload.button')}
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>{translate('resources.objections.upload.titleBulk')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {translate('resources.objections.upload.descriptionBulk')}
          </Typography>
          <CreateBase resource="uploads" redirect={redirect}>
            <SimpleForm toolbar={<SaveToolbar />} defaultValues={ isSingleMunicipality ? { municipalityId: municipalities[0]?.id } : null}>
              {!isSingleMunicipality && (
                <ReferenceInput
                  source="municipalityId"
                  reference="municipality"
                >
                  <SelectInput
                    optionText="name"
                    label="resources.objections.municipality"
                    helperText="resources.objections.upload.municipalityHelpText"
                  />
                </ReferenceInput>
              )}
              <FileInput
                source="files"
                label={translate('resources.objections.upload.uploadDocuments')}
                accept={{ 'application/pdf': ['.pdf'] }}
                placeholder={translate('resources.objections.properties.uploadExtraDocumentsPlaceholders')}
                disabled={!hasAtLeastOneMunicipality}
                helperText={!hasAtLeastOneMunicipality && 'Fout: Er is nog geen gemeente aangemaakt.'}
                isRequired
                validate={required()}
                multiple
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleForm>
          </CreateBase>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UploadMultipleDocumentsButton
