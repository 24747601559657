/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AuditLogOut */
export interface AuditLogOut {
  /** Audit Log Type */
  type: string;
  /** Description */
  description: string;
  /** User */
  user: string;
  /** Motivation */
  motivation?: string | null;
  /** Objection Number */
  objection_number?: string | null;
  /** Object WOZ Number */
  object_woz_number?: string | null;
  /** Object Reference Number */
  object_reference_number?: string | null;
  /** External Reference */
  external_reference?: string | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the audit log
   * @format uuid
   */
  id: string;
}

/** Body_create_objection_api_v1_objection_post */
export interface BodyCreateObjectionApiV1ObjectionPost {
  /** Municipalityid */
  municipalityId?: string | null;
  /** Municipalitycode */
  municipalityCode?: string | null;
  /** Objection Number */
  objection_number?: string | null;
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_create_upload_api_v1_uploads_post */
export interface BodyCreateUploadApiV1UploadsPost {
  /** Files */
  files: File[];
  /** Original Filenames */
  original_filenames: string[];
  /** Municipalityid */
  municipalityId?: string | null;
  /** Municipalitycode */
  municipalityCode?: string | null;
}

/** Body_update_objection_documents_api_v1_objection__id__documents_put */
export interface BodyUpdateObjectionDocumentsApiV1ObjectionIdDocumentsPut {
  /** Files */
  files: File[];
  /** Original Filenames */
  original_filenames: string[];
}

/** ConfigurationOut */
export interface ConfigurationOut {
  /**
   * Unique identifier
   * @format uuid
   */
  id: string;
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** ConfigurationUpdate */
export interface ConfigurationUpdate {
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** CourtCreate */
export interface CourtCreate {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
}

/** CourtOut */
export interface CourtOut {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  id: string;
}

/** CourtUpdate */
export interface CourtUpdate {
  /** Court Name */
  name: string;
  /** Team Name */
  teamName: string;
  /** Mailbox */
  mailbox: string;
  /** Postal Code */
  postalCode: string;
  /** City */
  city: string;
}

/** FileType */
export enum FileType {
  BEZWAAR = "BEZWAAR",
  AANVULLING = "AANVULLING",
  HOORVERSLAG = "HOORVERSLAG",
  TAXATIE = "TAXATIE",
  MACHTIGING = "MACHTIGING",
  ONBEKEND = "ONBEKEND",
}

/** Grievance */
export interface Grievance {
  /**
   * Unique identifier of the grievance
   * @format uuid
   */
  id: string;
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: string;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** GrievanceCreate */
export interface GrievanceCreate {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: string;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** GrievanceImportOut */
export interface GrievanceImportOut {
  /**
   * Dummy response ID
   * @format uuid
   */
  id: string;
  /** Total number of grievances in source organization */
  total: number;
  /** Number of grievances actually imported */
  imported: number;
}

/** GrievanceImportRequest */
export interface GrievanceImportRequest {
  /** Source organization ID */
  organization: string;
}

/** GrievanceOut */
export interface GrievanceOut {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: string;
  /** List of decision texts */
  decision_texts: GrievanceText[];
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the grievance
   * @format uuid
   */
  id: string;
}

/** GrievanceStats */
export interface GrievanceStats {
  /** Name */
  name: string;
  /** Count */
  count: number;
}

/** GrievanceText */
export interface GrievanceText {
  /** Decision on the grievance */
  decision: string;
  /** Value influence of the grievance */
  valueInfluence: boolean;
  /** Descriptions of the grievance text */
  description: string;
}

/** GrievanceUpdate */
export interface GrievanceUpdate {
  /** Grievance name */
  name: string;
  /** Grievance description */
  description: string;
  /** Grievance explanation */
  explanation: string;
  /** A identifier of the grievance */
  grievanceNumber: string;
  /** List of decision texts */
  decision_texts: GrievanceText[];
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Inboekvariabelen */
export interface InboekvariabelenInput {
  /** Dagtekening */
  dagtekening?: string | null;
  /** Waardepeildatum */
  WaardePeildatum?: string | null;
  /** Proforma */
  ProForma: boolean;
  /** Ontvankelijk */
  Ontvankelijk: boolean;
  /** Horen */
  Horen: boolean;
  /** Taxatieverslag */
  Taxatieverslag: boolean;
  /** Taxatiegesprokenmet */
  TaxatieGesprokenMet?: string | null;
  /** Taxatiesoortopname */
  TaxatieSoortOpname?: string | null;
  /** Taxatieobjectomschrijving */
  TaxatieObjectOmschrijving?: string | null;
  /** Onkostenvergoeding */
  Onkostenvergoeding: boolean;
  Vergoeding?: VergoedingOnderdelen | null;
  /** Uitstelbetaling */
  UitstelBetaling: boolean;
  /** Voldoetaanformelevereisten */
  VoldoetAanFormeleVereisten?: boolean | null;
  /** Vergelekenwoningen */
  VergelekenWoningen?: VergelekenWoning[] | null;
}

/** Inboekvariabelen */
export interface InboekvariabelenOutput {
  /** Dagtekening */
  dagtekening?: string | null;
  /** Waardepeildatum */
  WaardePeildatum?: string | null;
  /** Proforma */
  ProForma: boolean;
  /** Ontvankelijk */
  Ontvankelijk: boolean;
  /** Horen */
  Horen: boolean;
  /** Taxatieverslag */
  Taxatieverslag: boolean;
  /** Taxatiegesprokenmet */
  TaxatieGesprokenMet?: string | null;
  /** Taxatiesoortopname */
  TaxatieSoortOpname?: string | null;
  /** Taxatieobjectomschrijving */
  TaxatieObjectOmschrijving?: string | null;
  /** Onkostenvergoeding */
  Onkostenvergoeding: boolean;
  Vergoeding?: VergoedingOnderdelen | null;
  /** Uitstelbetaling */
  UitstelBetaling: boolean;
  /** Voldoetaanformelevereisten */
  VoldoetAanFormeleVereisten?: boolean | null;
  /** Vergelekenwoningen */
  VergelekenWoningen?: VergelekenWoning[] | null;
}

/** MunicipalityCreate */
export interface MunicipalityCreate {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Grievances */
  grievances: string[] | null;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  courtId: string;
}

/** MunicipalityGrievanceCreate */
export interface MunicipalityGrievanceCreate {
  /** Grievance number of the grievance */
  grievanceNumber: string;
  /** Identification number of the municipality */
  gemeentecode: string;
}

/** MunicipalityGrievanceOutWithGrievance */
export interface MunicipalityGrievanceOutWithGrievance {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality grievance
   * @format uuid
   */
  id: string;
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
  /** Grievance details */
  grievance: Grievance;
}

/** MunicipalityGrievanceUpdate */
export interface MunicipalityGrievanceUpdate {
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
}

/** MunicipalityOut */
export interface MunicipalityOut {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Grievances */
  grievances: string[] | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  courtId: string;
  /** Municipality Grievances */
  municipality_grievances: AppApiApiV1RoutersSchemasMunicipalitiesSchemasMunicipalityGrievanceOut[];
}

/** MunicipalityUpdate */
export interface MunicipalityUpdate {
  /** Municipality Code */
  municipalityCode: string;
  /** Municipality Name */
  name: string;
  /** Phone Number */
  phoneNumber: string;
  /** Grievances */
  grievances: string[] | null;
  /**
   * Unique identifier of the court
   * @format uuid
   */
  courtId: string;
}

/** ObjectionDecision */
export enum ObjectionDecision {
  GROUNDED = "GROUNDED",
  UNFOUNDED = "UNFOUNDED",
}

/** ObjectionFileOut */
export interface ObjectionFileOut {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Objection Id
   * @format uuid
   */
  objection_id: string;
  /** S3 Key */
  s3_key: string;
  /** Original Filename */
  original_filename: string;
  file_type?: FileType | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
}

/** ObjectionGrievanceBase */
export interface ObjectionGrievanceBase {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Objection Id
   * @format uuid
   */
  objection_id: string;
  /**
   * Grievance Id
   * @format uuid
   */
  grievance_id: string;
  /** Citaat */
  citaat: string;
  /** Additional Info */
  additional_info?: string | null;
  /** Decision Text Number */
  decision_text_number?: number | null;
  decision?: ObjectionDecision | null;
  /** Valueinfluence */
  valueInfluence?: boolean | null;
  /** Letter Advice Text */
  letter_advice_text?: string | null;
  /** Ready */
  ready?: boolean | null;
}

/** ObjectionGrievanceCreate */
export interface ObjectionGrievanceCreate {
  /**
   * Objection Id
   * @format uuid
   */
  objection_id: string;
  /**
   * Grievance Id
   * @format uuid
   */
  grievance_id: string;
  /** Citaat */
  citaat: string;
}

/** ObjectionGrievanceCreateOrUpdate */
export interface ObjectionGrievanceCreateOrUpdate {
  /** Id */
  id?: string | null;
  /**
   * Grievance Id
   * @format uuid
   */
  grievance_id: string;
  /** Objection Id */
  objection_id?: string | null;
  /** Citaat */
  citaat?: string | null;
  /** Additional Info */
  additional_info?: string | null;
  /** Decision Text Number */
  decision_text_number?: number | null;
  /** Letter Advice Text */
  letter_advice_text?: string | null;
  /** Ready */
  ready?: boolean | null;
}

/** ObjectionGrievanceOut */
export interface ObjectionGrievanceOut {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Objection Id
   * @format uuid
   */
  objection_id: string;
  /**
   * Grievance Id
   * @format uuid
   */
  grievance_id: string;
  /** Citaat */
  citaat: string;
  /** Additional Info */
  additional_info?: string | null;
  /** Decision Text Number */
  decision_text_number?: number | null;
  decision?: ObjectionDecision | null;
  /** Valueinfluence */
  valueInfluence?: boolean | null;
  /** Letter Advice Text */
  letter_advice_text?: string | null;
  /** Ready */
  ready?: boolean | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
}

/** ObjectionOut */
export interface ObjectionOut {
  /** Reference number of the object */
  object_reference_number?: string | null;
  /** WOZ object number */
  object_woz_number?: string | null;
  /** City of the object */
  object_city?: string | null;
  /** Street of the object */
  object_street?: string | null;
  /** House number of the object */
  object_house_number?: string | null;
  /** Postal code of the object */
  object_postal_code?: string | null;
  /** Name of the requestor */
  subject_name?: string | null;
  /** City of the requestor */
  subject_city?: string | null;
  /** Street of the requestor */
  subject_street?: string | null;
  /** House number of the requestor */
  subject_house_number?: string | null;
  /** Postal code of the requestor */
  subject_postal_code?: string | null;
  /** Decision on the objection */
  decision?: ObjectionDecision | null;
  /** Motivation for the decision */
  decision_motivation?: string | null;
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the objection
   * @format uuid
   */
  id: string;
  /** Organization ID */
  organization_id: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  objection_number: string;
  /** Inbook variables */
  inboekvariabelen: InboekvariabelenOutput;
  /** Established value in euros */
  established_value?: number | null;
  /** Claimed value in euros by the objector */
  claimed_value?: number | null;
  /** Corrected value after review */
  corrected_value?: number | null;
  /** List of objection grievances */
  objection_grievances: ObjectionGrievanceBase[];
  /** List of objection grievances */
  objection_documents: ObjectionFileOut[];
  /** Type of the objection */
  type?: ObjectionType | null;
  /** External reference number */
  external_reference?: string | null;
  /** Deleted date for this objection. */
  deleted?: string | null;
}

/** ObjectionStatus */
export enum ObjectionStatus {
  RECEIVED = "RECEIVED",
  UNDER_REVIEW = "UNDER_REVIEW",
  ADDITIONAL_INFORMATION_REQUESTED = "ADDITIONAL_INFORMATION_REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REVISION_REQUESTED = "REVISION_REQUESTED",
  RESOLVED = "RESOLVED",
  CANCELLED = "CANCELLED",
}

/** ObjectionStatusData */
export interface ObjectionStatusData {
  /** X */
  x: string;
  /** Y */
  y: number;
}

/** ObjectionType */
export enum ObjectionType {
  PARTICULIER = "PARTICULIER",
  EERLIJKE_WOZ = "EERLIJKE_WOZ",
  HET_NIEUWE_WOZ_BUREAU = "HET_NIEUWE_WOZ_BUREAU",
  JUIST = "JUIST",
  BEZWAAR_MAKER = "BEZWAAR_MAKER",
  WOZ_MELDPUNT = "WOZ_MELDPUNT",
}

/** ObjectionUpdate */
export interface ObjectionUpdate {
  /** Reference number of the object */
  object_reference_number?: string | null;
  /** WOZ object number */
  object_woz_number?: string | null;
  /** City of the object */
  object_city?: string | null;
  /** Street of the object */
  object_street?: string | null;
  /** House number of the object */
  object_house_number?: string | null;
  /** Postal code of the object */
  object_postal_code?: string | null;
  /** Name of the requestor */
  subject_name?: string | null;
  /** City of the requestor */
  subject_city?: string | null;
  /** Street of the requestor */
  subject_street?: string | null;
  /** House number of the requestor */
  subject_house_number?: string | null;
  /** Postal code of the requestor */
  subject_postal_code?: string | null;
  /** Decision on the objection */
  decision?: ObjectionDecision | null;
  /** Motivation for the decision */
  decision_motivation?: string | null;
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  objection_number: string;
  /** Inbook variables */
  inboekvariabelen: InboekvariabelenInput;
  /** Established value in euros */
  established_value?: number | null;
  /** Claimed value in euros by the objector */
  claimed_value?: number | null;
  /** Corrected value after review */
  corrected_value?: number | null;
  /** Create letter for the objection */
  createLetter?: boolean | null;
  /** List of objection grievances to update */
  objection_grievances: ObjectionGrievanceCreateOrUpdate[];
}

/** OrganizationOut */
export interface OrganizationOut {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Name */
  name: string;
  /** Organizationid */
  organizationId: string;
}

/** StatsOut */
export interface StatsOut {
  /** New objections */
  newObjections: number;
  /** Increase in objections */
  objectionsIncrease: string;
  /** New files */
  newFiles: number;
  /** Increase in files */
  filesIncrease: string;
  /** Total objections */
  total: number;
  /** Completed objections */
  completed: number;
  /** Top grievances */
  topGrievances: GrievanceStats[];
  /** Statuscounts */
  statusCounts: StatusCounts[];
  /** Unique identifier of the requestor */
  id: string;
}

/** StatusCounts */
export interface StatusCounts {
  /** Id */
  id: string;
  /** Data */
  data: ObjectionStatusData[];
}

/** TaxatieType */
export enum TaxatieType {
  BUREAU_TAXATIE = "BUREAU_TAXATIE",
  TER_PLAATSE = "TER_PLAATSE",
  INPANDIG = "INPANDIG",
}

/** UploadFileOut */
export interface UploadFileOut {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** Objection Id */
  objection_id?: string | null;
  /** S3 Key */
  s3_key: string;
  /** Original Filename */
  original_filename: string;
  file_type?: FileType | null;
  /** Status Description */
  status_description?: string | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
}

/** UploadOut */
export interface UploadOut {
  /**
   * Id
   * @format uuid
   */
  id: string;
  status?: UploadStatus | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /** Upload Files */
  upload_files: UploadFileOut[];
}

/** UploadStatus */
export enum UploadStatus {
  PENDING = "PENDING",
  WORKING = "WORKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** VergelekenWoning */
export interface VergelekenWoning {
  /** Straat */
  straat: string;
  /** Huisnummer */
  huisnummer: string;
  /** Woonplaats */
  woonplaats: string;
  /** Bouwjaar */
  bouwjaar: string;
  /** Eenheid */
  eenheid: string;
  /** Oppervlakte */
  oppervlakte: string;
  /** Verkoopdatum */
  verkoopdatum: string;
  /** Verkoopprijs */
  verkoopPrijs: number;
  /** Kwaliteit */
  kwaliteit: number;
  /** Onderhoud */
  onderhoud: number;
  /** Ligging */
  ligging: number;
}

/** VergoedingOnderdelen */
export interface VergoedingOnderdelen {
  /** Indienenbezwaarschrift */
  indienenBezwaarschrift: boolean;
  /** Horen */
  horen: boolean;
  /** Taxatieverslag */
  taxatieVerslag: boolean;
  taxatieType?: TaxatieType | null;
}

/** MunicipalityGrievanceOut */
export interface AppApiApiV1RoutersSchemasMunicipalitiesSchemasMunicipalityGrievanceOut {
  /**
   * Unique identifier of the MunicipalityGrievance
   * @format uuid
   */
  municipality_grievance_id: string;
  /**
   * Unique identifier of the Grievance
   * @format uuid
   */
  grievance_id: string;
}

/** MunicipalityGrievanceOut */
export interface AppApiApiV1RoutersSchemasMunicipalityGrievanceSchemasMunicipalityGrievanceOut {
  /** Unique identifier of the grievance */
  grievances_sid: number;
  /** Unique identifier of the municipality */
  municipalities_sid: number;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the municipality grievance
   * @format uuid
   */
  id: string;
}
