import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { FC, useState } from 'react'
import {
  CreateBase,
  FileField,
  FileInput, required,
  SaveButton, SimpleForm,
  Toolbar, useNotify, useRefresh,
  useTranslate
} from 'react-admin'

const SaveToolbar = props => (
  <Toolbar {...props}>
    <SaveButton alwaysEnable />
  </Toolbar>
)

const ImportGrievancesWithCsvButton: FC = () => {
  const refresh = useRefresh()
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()

  const redirect = (_resource, _id, _data) => {
    setOpen(false)
    notify('resources.grievances.importMessage', { type: 'info' })
    refresh()
    return 'grievances'
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<FileUploadIcon />}
        variant="contained"
      >
        {translate('data.csvImportGrievances')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>{translate('resources.grievances.import.title')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {translate('resources.grievances.import.description')}
          </Typography>
          <CreateBase resource="grievances/import/csv" redirect={redirect}>
            <SimpleForm toolbar={<SaveToolbar />}>
              <FileInput
                source="file"
                label={translate('resources.grievances.import.uploadDocument')}
                accept={{
                  'text/csv': ['.csv'],
                  'application/vnd.ms-excel': ['.xls'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                placeholder={translate('resources.grievances.import.uploadPlaceholder')}
                isRequired
                validate={required()}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleForm>
          </CreateBase>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ImportGrievancesWithCsvButton
