import Download from '@mui/icons-material/Download'
import { FC, useCallback } from 'react'
import { Button, useRecordContext, useTranslate } from 'react-admin'
import { getUrl } from '../../providers/data'
import { ObjectionOut } from '../../types/apiRequests'
import authToken from '../../utils/authToken'
import getLetterAdviceText from '../../utils/getLetterAdviceTexts'

interface Props {
    type: 'letter' | 'pdf'
    label?: string
    sx?: object
}

export const DownloadFileButton: FC<Props> = ({ type, label, sx }) => {
  const record = useRecordContext<ObjectionOut>()
  const translate = useTranslate()
  const letterAdviceTexts = getLetterAdviceText(record)

  const disabled = type === 'letter' && letterAdviceTexts.length === 0
  const fileName = `${type}_${record?.objection_number}_${record?.gemeentecode}.pdf`

  const token = authToken.getToken() || ''
  const url = `${getUrl()}/objection/${record?.id}/${type}`

  const handleClick = useCallback(
    () => {
      const orgIdOverride = localStorage.getItem('org_id_override')
      let optionalHeader = {}
      if (orgIdOverride) {
        optionalHeader = { organization: orgIdOverride }
      }
      fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: token,
          ContentType: 'text/plain',
          ...optionalHeader,
        },
      }).then(async (data) => {
        if (!data.ok) {
          throw new Error(`HTTP error! status: ${data.status}`)
        }
        const blob = await data.blob()
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
        .catch(error => {
          console.error(error)
        })
    }, [record]
  )

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      label={translate(label ?? `data.downloadObjection_${type}`)}
      sx={sx}
      disabled={disabled}
    >
      {<Download />}
    </Button>
  )
}