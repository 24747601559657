import { CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid,
  FilterLiveSearch,
  List,
  ListProps,
  TextField,
  useTranslate
} from 'react-admin'
import { FilterSidebarCard, ListActionsToolbar } from '../../components'
import { ImportGrievancesButton } from '../../components/buttons/ImportGrievancesButton'
import ImportGrievancesWithCsvButton from '../../components/buttons/ImportGrievancesWithCsvButton'
import { LocationCreateButton } from '../../components/buttons/LocationCreateButton'
import { OrganizationWarningCard } from '../../components/cards/OrganizationWarningCard'

const FilterSidebar: FC = () => {
  const translate = useTranslate()

  return (
    <FilterSidebarCard>
      <CardContent>
        <Typography variant="h6" component="div">
          {translate('filters.title')}
        </Typography>
        <FilterLiveSearch source="q" />
      </CardContent>
    </FilterSidebarCard>
  )
}

const GrievancesList: FC<ListProps> = (props) => (
  <>
    <OrganizationWarningCard />
    <StyledList
      {...props}
      sx={{ marginLeft: '0 !important' }}
      exporter={false}
      empty={false}
      aside={<FilterSidebar />}
      actions={
        <ListActionsToolbar i18n="resources.grievances.list">
          <ImportGrievancesButton
            access={['consultancy:all']}
          />
          <ImportGrievancesWithCsvButton />
          <LocationCreateButton
            access={['configuration:all']}
            resource="grievances"
            label="ra.action.save"
          />
        </ListActionsToolbar>
      }
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField
          source="grievanceNumber"
          label="data.grievanceNumber"
        />
        <TextField
          source="name"
          label="data.name"
        />
      </Datagrid>
    </StyledList>
  </>
)

const PREFIX = 'xxllncGrievancesList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default GrievancesList
