import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { getUrl } from '../../providers/data'
import { ObjectionFileOut, ObjectionOut } from '../../types/apiRequests'
import authToken from '../../utils/authToken'

interface Props {
  showSideBySide: boolean;
  setShowSideBySide: (val: boolean) => void;
  pdfUrl: string | null;
  setPdfUrl: (val: string | null) => void;
}

const DocumentSideBySideSplitButton: FC<Props> = ({
  showSideBySide,
  setShowSideBySide,
  pdfUrl,
  setPdfUrl,
}) => {
  const record = useRecordContext<ObjectionOut>()
  const translate = useTranslate()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const documents = record?.objection_documents

  const fetchAndShowSideBySide = useCallback(async (doc: ObjectionFileOut) => {
    const token = authToken.getToken() || ''
    const url = `${getUrl()}/objection_files/${doc.id}/download`

    try {
      const orgIdOverride = localStorage.getItem('org_id_override')
      let optionalHeader = {}
      if (orgIdOverride) {
        optionalHeader = { organization: orgIdOverride }
      }
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: { Authorization: token, ...optionalHeader },
      })
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const blob = await response.blob()
      const pdfBlob = new Blob([blob], { type: 'application/pdf' })
      const objectUrl = URL.createObjectURL(pdfBlob)

      setPdfUrl(objectUrl)
      setShowSideBySide(true)
    } catch (error) {
      console.error('Error fetching PDF for side-by-side:', error)
    }
  }, [setPdfUrl, setShowSideBySide])

  const hideSideBySide = () => {
    setShowSideBySide(false)
  }

  const handleMainButtonClick = async () => {
    if (!documents) return
    if (documents.length === 1) {
      if (showSideBySide) {
        hideSideBySide()
      } else {
        await fetchAndShowSideBySide(documents[0])
      }
    } else {
      if (showSideBySide) {
        hideSideBySide()
      } else {
        await fetchAndShowSideBySide(documents[0])
      }
    }
  }

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleMenuItemClick = async (doc: ObjectionFileOut) => {
    await fetchAndShowSideBySide(doc)
    setOpen(false)
  }

  const handleHideSideBySideClick = () => {
    hideSideBySide()
    setOpen(false)
  }

  if (!record?.objection_documents || record.objection_documents.length === 0) {
    return null
  }

  if (documents?.length === 1) {
    return (
      <Button
        onClick={() => void handleMainButtonClick()}
        variant="outlined"
        sx={{ fontSize: '0.75rem', ml: 2 }}
      >
        {!showSideBySide ? (
          <>
            <RemoveRedEyeIcon sx={{ mr: 1 }} />
            {translate('resources.objections.sideBySide')}
          </>
        ) : (
          <>
            <VisibilityOffIcon sx={{ mr: 1 }} />
            {translate('resources.objections.hideSideBySide')}
          </>
        )}
      </Button>
    )
  }

  return (
    <ButtonGroup
      variant="contained"
      ref={anchorRef}
      sx={{
        'boxShadow': 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      }}
      aria-label="split button for side-by-side"
    >
      <Button
        variant="outlined"
        sx={{ flexGrow: 2 }}
        onClick={() => void handleMainButtonClick()}
      >
        <Typography sx={{ fontSize: '0.8125rem', fontWeight: '500' }}>
          {!showSideBySide
            ? translate('resources.objections.sideBySide')
            : translate('resources.objections.hideSideBySide')}
        </Typography>
      </Button>
      <Button
        size="small"
        variant="outlined"
        sx={{
          borderBottomRightRadius: '24px!important',
          borderTopRightRadius: '24px!important'
        }}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <ArrowDropDownIcon />
      </Button>

      <Popper
        sx={{ zIndex: 1, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {documents?.map((doc) => (
                    <MenuItem
                      key={doc.id}
                      onClick={() => void handleMenuItemClick(doc)}
                    >
                      { doc.file_type ?
                        translate(`resources.objections.fileType.${doc.file_type}`) :
                        doc.original_filename}
                    </MenuItem>
                  ))}

                  {showSideBySide && (
                    <MenuItem onClick={handleHideSideBySideClick}>
                      {translate('resources.objections.hideSideBySide')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ButtonGroup>
  )
}

export default DocumentSideBySideSplitButton
