/* eslint-disable */
import { Box, MenuItem, Select, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { MenuItemLink, MenuProps, useGetList, usePermissions, useRefresh, useResourceDefinitions, useSidebarState, useTranslate } from 'react-admin'
import { Dot, Xxllnc } from '../components/Icons'
import useAutoLogout from '../hooks/useAutoLogout'
import { border, color, xxllncColor } from '../layout/themes'
import SubMenu from './SubMenu'

const Menu = ({ dense = false }: MenuProps): JSX.Element => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const { permissions } = usePermissions()
  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name])
  const isSmall = useMediaQuery('(min-width:600px)')
  const [open, setOpen] = useSidebarState()
  const [state, setState] = useState({ menuSettings: false })
  const [selectedOrg, setSelectedOrg] = useState(localStorage.getItem('org_id_override'))
  const [_selectedOrgName, setSelectedOrgName] = useState(localStorage.getItem('org_name_override'))

  const { data: organizations = [] } = permissions?.scopes?.includes("consultancy:all") ? useGetList('organization') : { data: [] }


  useAutoLogout()

  useEffect(() => {
    if (!isSmall) setOpen(true)
  }
  , [isSmall, setOpen])


  const handleOrgChange = (event) => {
    const newOrgId = event.target.value
    const newOrgName = organizations.find(org => org.organizationId === newOrgId)?.name || ''
    setSelectedOrg(newOrgId)
    setSelectedOrgName(newOrgName)
    localStorage.setItem('org_id_override', newOrgId)
    localStorage.setItem('org_name_override', newOrgName)
    window.dispatchEvent(new Event('orgChange'))
    refresh()
  }

  const handleToggle = (menu: MenuName) => {
    setState(stateItem => ({ ...stateItem, [menu]: !stateItem[menu] }))
  }

  const getMenuItems = (object) => {
    let values = object.filter(resource => resource.icon && !resource.options?.submenu)
    const submenu = object.filter(resource => resource.icon && resource.options?.submenu)

    if (submenu.length > 0) {
      values = [
        ...values,
        {
          name: 'submenu.settings',
          resources: submenu.map(prop => prop.name),
          submenu: [
            ...submenu
          ]
        }
      ]
    }

    return values
  }

  return (
    <StyledBox mt={1} className={classes.root}>
      <div className={classes.branding}>
        <Xxllnc className={classes.logo}  />
        <Typography
          component="span"
          color="textPrimary"
          className={classes.lab}
        >
          Bezwaren
        </Typography>
      </div>
      <div className={classes.spacer}>&nbsp;</div>
      <StyledMenuItemLink
        className={classes.item}
        dense={dense}
        key={'dashboard'}
        primaryText="Dashboard"
        sidebarIsOpen={!open}
        to={`/`}
      />
      {getMenuItems(resources).map(resource => {
        if ('submenu' in resource) {
          return (
            <SubMenu
              key={resource.name}
              handleToggle={() => handleToggle('menuSettings')}
              isOpen={state.menuSettings}
              sidebarIsOpen={!open}
              name={resource.name}
              dense={dense}
            >
              {resource && resource.submenu && resource.submenu.map(item => (
                <StyledMenuItemLink
                  className={classes.link}
                  classes={classes}
                  dense={dense}
                  key={item.name}
                  leftIcon={<Dot fill='#fff' />}
                  primaryText={item.options?.label || item.name}
                  sidebarIsOpen={!open}
                  to={`/${item.name}`}
                />
              ))}
            </SubMenu>
          )}
        return (
          <StyledMenuItemLink
            className={classes.item}
            dense={dense}
            key={resource.name}
            primaryText={resource.options?.label || resource.name}
            sidebarIsOpen={!open}
            to={`/${resource.name}`}
          />
        )
      })}
      <div className={classes.footer}>
      {permissions?.scopes?.includes("consultancy:all") && (
        <Select
          value={selectedOrg}
          onChange={handleOrgChange}
          displayEmpty
          className={classes.select}
          renderValue={(value) =>
            value ? organizations.find(org => org.organizationId === value)?.name : translate("data.selectOrganization")
          }
        >
          {organizations.map(org => (
            <MenuItem key={org.id} value={org.organizationId}>{org.name}</MenuItem>
          ))}
        </Select>
      )}
      </div>
    </StyledBox>
  )
}

type MenuName = 'menuSettings'

const PREFIX = 'xxllncMenu'

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
  branding: `${PREFIX}-branding`,
  logo: `${PREFIX}-logo`,
  lab: `${PREFIX}-lab`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  item: `${PREFIX}-item`,
  spacer: `${PREFIX}-spacer`,
  select: `${PREFIX}-select`,
  footer: `${PREFIX}-footer`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.select}`]: {
    margin: '10px',
    width: '90%',
    color: xxllncColor.light100,
  },
  [`& .${classes.link}`]: {
    [theme.breakpoints.up('sm')]: {
      color: xxllncColor.light100,
      ['&:hover']: {
        backgroundColor: xxllncColor.dark100,
      },
    }
  },
  [`& .${classes.item}`]: {
    padding: '12px 20px',
    ['&:hover']: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: xxllncColor.dark100,
      }
    }
  },
  [`& .${classes.branding}`]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`& .${classes.logo}`]: {
    fill: color.common.inactive,
    width: theme.spacing(10),
    marginTop: theme.spacing(1.3),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
  },
  [`& .${classes.lab}`]: {
    backgroundColor: xxllncColor.opDark80,
    color: xxllncColor.opLight60,
    borderRadius: border.radius.lg,
    fontSize: 9,
    fontWeight: 700,
    marginTop: '14px',
    padding: '5px 8px',
  },
  [`& .${classes.spacer}`]: {
    height: 40,
  },
  [`& .${classes.footer}`]: {
    textAlign: 'center',
    color: '#fff',
    position: 'absolute',
    bottom: 10,
    width: '100%',
    left: 0,
    },
}))

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  ['&.RaMenuItemLink-active']: {
    backgroundColor: xxllncColor.opDark80,
    color: xxllncColor.light100,
  },
  ['& .RaMenuItemLink-icon']: {
    color: xxllncColor.opDark80,
    minWidth: theme.spacing(3),
  },
}))

export default Menu
