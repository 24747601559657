import { CardContent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  BulkDeleteButton,
  Datagrid,
  EditButton,
  FilterLiveSearch, FunctionField, List,
  ListProps,
  useTranslate
} from 'react-admin'
import {
  ConditionalDateField,
  ConditionalFunctionField,
  ConditionalNumberField,
  ConditionalTextField,
  DeletedFilter,
  FilterSidebarCard,
  ListActionsToolbar,
  ReadyFilter,
  StatusFilter,
  TypeFilter
} from '../../components'
import UploadMultipleDocumentsButton from '../../components/buttons/UploadUnknownDocumentsButton'
import { OrganizationWarningCard } from '../../components/cards/OrganizationWarningCard'
import { ObjectionOut } from '../../types/apiRequests'
import StatusIcon from './StatusIcon'

const ObjectionBulkActionButtons = () => (
  <>
    <BulkDeleteButton />
  </>
)

const FilterSidebar: FC = () => {
  const translate = useTranslate()

  return (
    <FilterSidebarCard>
      <CardContent>
        <Typography variant="h6" component="div">
          {translate('filters.title')}
        </Typography>
        <FilterLiveSearch source="q" />
        <StatusFilter />
        <TypeFilter />
        <ReadyFilter />
        <DeletedFilter />
      </CardContent>
    </FilterSidebarCard>
  )
}

const ObjectionsList: FC<ListProps> = (props) => {
  const translate = useTranslate()

  return (
    <>
      <OrganizationWarningCard />
      <StyledList
        {...props}
        sx={{ marginLeft: '0 !important' }}
        exporter={false}
        aside={<FilterSidebar />}
        actions={
          <ListActionsToolbar i18n="resources.objections.list">
            <UploadMultipleDocumentsButton />
          </ListActionsToolbar>
        }
        sort={{ field: 'created', order: 'DESC' }}
        filterDefaultValues={{ excludeDeleted: true }}
      >
        <Datagrid bulkActionButtons={<ObjectionBulkActionButtons />} rowClick="show">
          <ConditionalFunctionField
            source="status"
            label="data.status"
            render={(record: ObjectionOut) => (
              <StatusIcon
                status={record.status}
                created={record.created}
              />
            )}
          />
          <ConditionalNumberField
            source="objection_number"
            label="data.objection_number"
            emptyText="-"
          />
          <ConditionalFunctionField
            source="type"
            label="data.referentieNummer"
            render={(record: ObjectionOut) => record?.object_woz_number || record?.object_reference_number || '-'}
            sortable={false}
          />
          <ConditionalFunctionField
            source="type"
            label="data.objectionType"
            render={(record: ObjectionOut) => translate(`resources.objections.objectionType.${record?.type || 'UNKNOWN'}`)}
            sortable={false}
          />
          <ConditionalTextField
            source="subject_name"
            label="data.subject"
            sortable={false}
          />
          <ConditionalFunctionField
            source="type"
            label="data.address"
            render={(record: ObjectionOut) => (
              `${record?.object_street || '-'} ${record?.object_house_number || ''}, ${record?.object_city || ''}`
            )}
            sortable={false}
          />
          <ConditionalDateField
            source="created"
            label="data.created"
          />
          <FunctionField
            source="deleted"
            label=""
            sortable={false}
            render={(record: ObjectionOut) => <EditButton disabled={!!record?.deleted} />}
          />
        </Datagrid>
      </StyledList>
    </>
  )
}

const PREFIX = 'xxllncToDosList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default ObjectionsList
