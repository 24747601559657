import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Alert } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import authToken from '../../utils/authToken'

export const OrganizationWarningCard = () => {
  const translate = useTranslate()
  const organization = authToken.getOrganization()

  const [organizationOverwrite, setOrganizationOverwrite] = useState(localStorage.getItem('org_id_override'))

  useEffect(() => {
    const handleStorageChange = () => {
      console.log('org_id_override changed', localStorage.getItem('org_id_override'))
      setOrganizationOverwrite(localStorage.getItem('org_id_override'))
    }

    window.addEventListener('orgChange', handleStorageChange)

    return () => {
      window.removeEventListener('orgChange', handleStorageChange)
    }
  }, [])

  const changedOrganization = organizationOverwrite !== organization.organization.organizationId

  if (!organizationOverwrite || !changedOrganization) return null

  return (
    <Alert
      severity={'warning'}
      sx={{ alifgnSelf: 'center', marginX: '1rem', marginBottom: '1rem' }}
      icon={<WarningAmberIcon />}
    >
      {translate('resources.objections.organizationWarning', { organization: localStorage.getItem('org_name_override') || '' })}
    </Alert>
  )
}
