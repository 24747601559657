import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Button, Menu, MenuItem } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { useCreate, useGetList, useNotify, useRefresh, useTranslate } from 'react-admin'
import { RestrictedButton } from '../../components'
import { OrganizationOut } from '../../types/apiRequests'
import { ContainerAlign } from './ButtonTypes'

export const ImportGrievancesButton: FC<ImportGrievancesButtonProps> = (props) => {
  const { access, align } = props
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { data: organizations = [] } = useGetList<OrganizationOut>('organization')
  const organizationOverrideId = localStorage.getItem('org_id_override')
  const organizationId = localStorage.getItem('organizationId')

  const handleSelect = async (orgId: string) => {
    handleClose()
    await create('grievances/import', { data: { organization: orgId } })
    notify('resources.grievances.importMessage', { type: 'info' })
    refresh()
  }

  return (
    <RestrictedButton
      access={access}
      align={align || 'right'}
    >
      {(): JSX.Element => (
        <>
          <Button
            variant="contained"
            startIcon={<FileUploadIcon />}
            disabled={!organizationOverrideId || organizationId === organizationOverrideId}
            onClick={handleClick}
          >
            {translate('data.importGrievances')}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {organizations.filter(org => org.organizationId !== organizationOverrideId)
              .map((org) => (
                <MenuItem
                  key={org.organizationId}
                  onClick={() => void handleSelect(org.organizationId)}
                >
                  {org.name}
                </MenuItem>
              ))}
          </Menu>
        </>
      )}
    </RestrictedButton>
  )
}

interface ImportGrievancesButtonProps {
    access: string[];
    align?: ContainerAlign;
  }
